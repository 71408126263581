import Vue from 'vue';
import Moment from 'vue-moment';
import VueSocialSharing from 'vue-social-sharing';
import VueGtag from 'vue-gtag';
import VueMeta from 'vue-meta';
import { BootstrapVue } from 'bootstrap-vue';
import process from 'process';
import { Buffer } from 'buffer';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import { store, fetcher } from './store';

// importing bootstrap theme styles
import './theme/App.scss';

// Load polyfills in a dirty hacky way
window.process = process;
window.Buffer = Buffer;
Vue.prototype.$standalone = false;
Vue.prototype.$utmSource = 'testing';

Vue.use(VueGtag, {
  config: {
    id: process.env.VUE_APP_GOOGLE_ANALYTICS || 'UA-XXXXX-X',
  },
  includes: [
    { id: process.env.VUE_APP_GOOGLE_ADS || 'UA-XXXXX-X' },
  ],
}, router);
Vue.use(Moment);
Vue.use(BootstrapVue);
Vue.use(VueSocialSharing);
Vue.use(VueMeta);

Vue.config.productionTip = false;

Vue.prototype.$fetcher = fetcher;

router.beforeEach((to, from, next) => {
  // eslint-disable-next-line no-underscore-dangle, no-multi-assign
  const hsq = window._hsq = window._hsq || [];
  if (hsq) {
    hsq.push(['setPath', to.path]);
    hsq.push(['trackPageView']);
  }
  next();
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
