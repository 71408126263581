import Vue from 'vue';
import Vuex from 'vuex';
import { rankings, fetcher } from './modules/rankings/rankings';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  getters: {},
  modules: {
    rankings,
  },
});

export {
  store,
  fetcher,
};
