<template>
  <BNavbar
    id="header"
    variant="dark"
    type="dark"
  >
    <BNavbarBrand href="/">
      <img
        alt="APImetrics logo"
        src="../assets/api-expert-logo-no-border.svg"
        class="d-inline-block align-top logopic"
      >
    </BNavbarBrand>
    <BNavbarNav>
      <BNavText
        class="icons"
      >
        <router-link :to="{ name: 'api-cloud-analysis' }">
          <UploadCloudIcon
            size="25"
            color="#00A5EF"
          />
          <span class="inline d-none d-md-inline">
            APIs 2020 Report
          </span>
        </router-link>
      </BNavText>
      <BNavText class="icons">
        <router-link :to="{ name: 'news' }">
          <BookOpenIcon
            size="25"
            color="#00A5EF"
          />
          <span class="inline d-none d-md-inline">
            News
          </span>
        </router-link>
      </BNavText>
      <BNavText
        class="icons"
      >
        <a
          v-b-tooltip.bottom="'Go to APImetrics home'"
          href="https://apimetrics.io/"
        >
          <UserIcon
            size="25"
            color="#00A5EF"
          />
          <span class="inline d-none d-md-inline">
            APImetrics
          </span>
        </a>
      </BNavText>
    </BNavbarNav>
  </BNavbar>
</template>

<script>
import {
  BNavbar, BNavbarBrand, BNavbarNav, BNavText,
} from 'bootstrap-vue';
import {
  BookOpenIcon,
  UploadCloudIcon,
  UserIcon,
} from 'vue-feather-icons';

export default {
  name: 'HeaderComponent',
  components: {
    BNavbar,
    BNavbarBrand,
    BNavbarNav,
    BNavText,
    BookOpenIcon,
    UploadCloudIcon,
    UserIcon,
  },
  computed: {
    projectsUrl() {
      return `${process.env.VUE_APP_AUTH0_AUDIENCE}/projects`;
    },
  },
  methods: {
    goToProjectsPage() {
      window.location = this.projectsUrl;
    },
  },
};
</script>
