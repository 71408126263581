/* eslint-disable max-classes-per-file */
import fetch from 'cross-fetch';
import fetchBuilder from 'fetch-retry';
import queryString from 'query-string';

const fetchRetry = fetchBuilder(fetch);

class APImError extends Error {
  constructor(response, ...params) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(`${response.url} returned ${response.status} ${response.statusText}`, ...params);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, APImError);
    }

    this.name = 'APImError';
    // Custom debugging information
    this.response = response;
  }
}

function handleFetchErrors(response) {
  if (!response.ok) {
    const error = new APImError(response);
    return Promise.reject(error);
  }
  return Promise.resolve(response);
}

class APImFetch {
  constructor() {
    this.baseUrl = process.env.VUE_APP_API_URL || '';
    this.standalone = false;
    let params = {};
    if (process.env.VUE_APP_API_PARAMS) {
      params = JSON.parse(process.env.VUE_APP_API_PARAMS);
    }
    this.defaultParams = params;
  }

  async fetch(fetchOptions) {
    const options = {
      method: fetchOptions.method || 'GET',
      headers: {
        ...(fetchOptions.headers || {}),
        Accept: 'application/json',
      },
      retryOn: [500, 503],
      retries: 3,
      retryDelay: 1000,
      credentials: process.env.VUE_APP_CREDENTIALS || 'same-origin',
    };
    if ('data' in fetchOptions) {
      options.body = JSON.stringify(fetchOptions.data);
    }
    const parameters = { ...this.defaultParams, ...(fetchOptions.parameters || {}) };
    const paramStr = queryString.stringify(parameters);
    if (this.standalone) {
      this.baseUrl = 'https://www.api.expert';
    }
    const url = `${this.baseUrl}${fetchOptions.path}?${paramStr}`;
    // console.debug(`fetch ${options.method} ${url}`);

    return fetchRetry(url, options)
      .then(handleFetchErrors)
      .then((response) => response.json());
  }

  get(path, parameters) {
    return this.fetch({ method: 'GET', path, parameters });
  }

  post(path, data, parameters, projectId = null) {
    const headers = {};
    if (projectId) {
      headers['Apimetrics-Project-Id'] = projectId;
    }
    return this.fetch({
      method: 'POST',
      path,
      parameters,
      data,
      headers,
    });
  }

  put(path, data, parameters) {
    return this.fetch({
      method: 'PUT',
      path,
      parameters,
      data,
    });
  }

  delete(path, parameters, projectId = null) {
    const headers = {};
    if (projectId) {
      headers['Apimetrics-Project-Id'] = projectId;
    }
    return this.fetch({
      method: 'DELETE', path, parameters, headers,
    });
  }

  getAll(path, pageCb, parameters, cursorIn, limitIn, resultsIn) {
    const cursor = cursorIn || null;
    const limit = limitIn || 100;
    const results = resultsIn || [];
    const params = { cursor, limit, ...parameters };

    return this.get(path, params).then((data) => {
      const { meta } = data;
      if (pageCb) {
        pageCb(data);
      }
      if (meta.more && meta.next_cursor) {
        return this.getAll(path, pageCb, parameters, meta.next_cursor, limit, [
          ...results,
          ...data.results,
        ]);
      }

      return {
        meta,
        results: [...results, ...data.results],
      };
    });
  }

  getOrgStats({
    orgId, kind = 'hour', start, end,
  }) {
    const url = `/api/org/${orgId}`;
    const params = {
      kind,
      start: `${start.format('YYYY-MM-DDTHH:mm:ss')}Z`,
      end: `${end.format('YYYY-MM-DDTHH:mm:ss')}Z`,
    };
    // https://client.apimetrics.io/api/2/api_expert/org/openbankingukproduction
    return this.get(url, params);
  }

  getWeekDate() {
    const url = '/data/current.json';
    return this.get(url);
  }

  getMonthDate() {
    const url = '/data/current_month.json';
    return this.get(url);
  }

  getYearDate() {
    const url = '/data/current_year.json';
    return this.get(url);
  }

  getColData(orgId, isMonth = false, isYear = false) {
    const postMonth = isMonth ? '_month' : '';
    const postfix = isYear ? '_year' : postMonth;
    const url = `/data/current${postfix}/${orgId}.json`;
    return this.get(url);
  }

  getColDetailData(orgId, isMonth = false, isYear = false) {
    const postMonth = isMonth ? '_month' : '';
    const postfix = isYear ? '_year' : postMonth;
    const url = `/data/current${postfix}/${orgId}_detail.json`;
    return this.get(url);
  }
}

export { APImFetch, APImError };
