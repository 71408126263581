/* eslint-disable no-shadow */
import Vue from 'vue';
import sortBy from 'lodash/sortBy';
// eslint-disable-next-line import/extensions
import { APImFetch, APImError } from '../../../fetch.js';
import {
  prepData, prepareDetailsData, allCollections,
} from '../dataprep/jsondata';

const DEFAULT_SORT = 'rank';
const fetcher = new APImFetch();

const state = {
  dataLoading: false,
  liveLoading: false,
  preppedApiData: [],
  selectedProjectId: '',
  monthRankingsList: [],
  currentlySortedBy: DEFAULT_SORT,
  ascending: false,
  colId: '',
  name: '',
  allTimingData: [],
  datesLoading: false,
  currentWeek: '',
  currentMonth: '',
  currentYear: '',
  viewMonth: false,
  viewYear: false,
  searchTerm: '',
  range: {
    start: null,
    end: null,
  },
  liveStats: [],
  emailSubs: {},
};

// Mutations
const DATES_LOADING = 'DATES_LOADING';
const SAVE_DATES = 'SAVE_DATES';
const DATA_LOADING = 'DATA_LOADING';
const SAVE_DATA = 'SAVE_DATA';
const LIVE_LOADING = 'LIVE_LOADING';
const SAVE_LIVE_STATS = 'SAVE_LIVE_STATS';
const SET_DATE_RANGE = 'SET_DATE_RANGE';
const SORT_BY = 'SORT_BY';
const SEARCH = 'SEARCH';
const CHANGE_TIME_PERIOD = 'CHANGE_TIME_PERIOD';
const SELECT_PROJECTID = 'SELECT_PROJECTID';

const getters = {
  dataLoading(state) {
    return state.datesLoading || state.dataLoading;
  },
  rankingsList(state) {
    let rankingsList = sortBy(state.preppedApiData, state.currentlySortedBy);
    if (!state.ascending) {
      rankingsList = rankingsList.reverse();
    }
    const newList = Object.values(rankingsList).filter((item) => {
      if (!state.searchTerm) {
        return item;
      }
      if (item.project_name) {
        return (
          item.project_name.toLowerCase().includes(state.searchTerm)
          || item.domain.toLowerCase().includes(state.searchTerm)
        );
      }
      return false;
    });
    return newList;
  },
  reallySelectedProjectId(state) {
    return state.selectedProjectId;
  },
  // We assume we want the first item in the ranking list if
  // the selected project Id is not set
  selectedProjectId(state, getters) {
    if (state.selectedProjectId) {
      return state.selectedProjectId;
    }
    const currentRankings = getters.rankingsList;
    if (currentRankings.length > 0) {
      return currentRankings[0].project_key_str;
    }
    return '';
  },
  currentWeek(state) {
    return state.datesLoading ? '' : state.currentWeek;
  },
  currentMonth(state) {
    return state.datesLoading ? '' : state.currentMonth;
  },
  currentYear(state) {
    return state.datesLoading ? '' : state.currentYear;
  },
  viewMonth(state) {
    return state.viewMonth;
  },
  viewYear(state) {
    return state.viewYear;
  },
  viewingPeriod(state) {
    if (state.viewYear) {
      return state.currentYear;
    }
    return state.viewMonth ? state.currentMonth : state.currentWeek;
  },
  viewingPeriodEnd(state) {
    if (state.viewYear) {
      return Vue.moment.utc(state.currentYear).add(1, 'years');
    }
    if (state.viewMonth) {
      return Vue.moment.utc(state.currentMonth).add(1, 'months');
    }
    return Vue.moment.utc(state.currentWeek).add(7, 'days');
  },
  viewingPeriodFull(state, getters) {
    const textInitial = state.viewYear ? 'Year of' : 'Week of';
    const text = state.viewMonth ? 'Month of' : textInitial;
    return `${text} ${Vue.moment
      .utc(getters.viewingPeriod)
      .format('ll')} to ${Vue.moment
      .utc(getters.viewingPeriodEnd)
      .subtract(1, 'days')
      .format('ll')}`;
  },
  colId(state) {
    return state.colId;
  },
  subColId(state) {
    const { group = null } = allCollections[state.colId] || {};
    return group || state.colId;
  },
  collections() {
    return allCollections;
  },
  currentCollection(state) {
    return allCollections[state.colId];
  },
  orgId(state) {
    return state.colId ? allCollections[state.colId].orgId : '';
  },
  fractalLogo(state) {
    return state.colId ? allCollections[state.colId].fractalLogo : false;
  },
  monthRankingsList(state) {
    return state.monthRankingsList;
  },
  allTimingData(state) {
    return state.allTimingData;
  },
  continentNames() {
    return {
      AS: 'Asia',
      AF: 'Africa',
      EU: 'Europe',
      NA: 'North America',
      OC: 'Oceania',
      SA: 'South America',
    };
  },
  timingData(state, getters) {
    return state.allTimingData.filter(
      (client) => client.project_key_str === getters.selectedProjectId,
    );
  },
  searchTerm(state) {
    return state.searchTerm;
  },
  name(state) {
    return state.name;
  },
  liveStats(state) {
    const allItems = Object.values(state.liveStats);
    if (!state.searchTerm) {
      return allItems;
    }
    return allItems.filter((item) => {
      return (
        item.project_name.toLowerCase().includes(state.searchTerm)
          || item.domain.toLowerCase().includes(state.searchTerm)
      );
    });
  },
  range(state) {
    return state.range;
  },
  selectedDomain(state, getters) {
    if (getters.timingData.length && getters.timingData[0]) {
      return getters.timingData[0].domain;
    }
    return '';
  },
};

const mutations = {
  [DATA_LOADING](state, loading) {
    state.dataLoading = loading;
  },
  [SAVE_DATA](state, {
    preppedApiData, allTimingData, colId,
  }) {
    state.preppedApiData = preppedApiData;
    state.allTimingData = allTimingData;
    state.colId = colId;
    state.name = (allCollections[colId] || {}).name || '';
    state.searchTerm = '';
    state.ascending = true;
    state.selectedProjectId = '';
  },
  [SELECT_PROJECTID](state, projectId) {
    state.selectedProjectId = projectId;
  },
  [SORT_BY](state, { columnName }) {
    if (columnName === state.currentlySortedBy && state.ascending) {
      state.ascending = false;
    } else {
      state.currentlySortedBy = columnName;
      state.ascending = true;
    }
  },
  [SEARCH](state, term) {
    state.searchTerm = term.toLowerCase();
    // reset any selected row from the table on search
    state.selectedProjectId = '';
  },
  [CHANGE_TIME_PERIOD](state, timeFrame) {
    state.viewMonth = timeFrame === 'month';
    state.viewYear = timeFrame === 'year';
  },
  [LIVE_LOADING](state, loading) {
    state.liveLoading = loading;
  },
  [SAVE_LIVE_STATS](state, { projects = {}, meta = {} }) {
    // Check we haven't changed org since we requested
    // this data
    if (meta.org_id && meta.org_id !== allCollections[state.colId].orgId) {
      return;
    }
    const liveStats = state.preppedApiData.map(
      (api) => ({
        ...api,
        results: projects[api.project_key_str] || false,
      }),
    );
    state.liveStats = liveStats;
  },
  [SET_DATE_RANGE](state, { start, end }) {
    state.range = { start, end };
  },
  [DATES_LOADING](state, loading) {
    state.datesLoading = loading;
  },
  [SAVE_DATES](state, { week, month, year }) {
    state.currentWeek = week;
    state.currentMonth = month;
    state.currentYear = year;
  },
};

const actions = {
  loadData: async ({ commit, getters, dispatch }, { colId, standalone = false }) => {
    commit(DATA_LOADING, true);
    if (!getters.currentWeek) {
      await dispatch('loadDates');
    }
    try {
      commit(SAVE_DATA, { preppedApiData: [], allTimingData: [], colId });

      const { orgId } = allCollections[colId];
      const colDataFut = fetcher.getColData(orgId, getters.viewMonth, getters.viewYear);
      const colDetailDataFut = fetcher.getColDetailData(orgId, getters.viewMonth, getters.viewYear);

      const apiData = await colDataFut;
      const preppedApiData = prepData(apiData);

      const apiDetail = await colDetailDataFut;
      const allTimingData = prepareDetailsData(apiDetail || []);

      commit(SAVE_DATA, { preppedApiData, allTimingData, colId });
      commit(SAVE_LIVE_STATS, {});
    } catch (ex) {
      // eslint-disable-next-line no-console
      console.log(ex);
    }
    commit(DATA_LOADING, false);
    if (!standalone) {
      dispatch('loadLiveData');
    }
  },
  sortRankingsBy: ({ commit }, columnName) => {
    commit(SORT_BY, { columnName });
  },
  search: ({ commit }, term) => {
    commit(SEARCH, term);
  },
  changeTimePeriod: ({ commit }, timeFrame) => {
    commit(CHANGE_TIME_PERIOD, timeFrame);
  },
  selectProject: ({ commit }, projectId) => {
    commit(SELECT_PROJECTID, projectId);
  },
  handleException: async (except) => {
    const error = { exception: except.toString() };
    try {
      if (except instanceof APImError) {
        error.message = except.message;
        error.status = except.response.status;
        error.statusText = except.response.statusText;
        error.body = await except.response.json();
      }
    } catch (ex) {
      // console.error(ex);
    }
    return error;
  },
  loadLiveData: async ({ commit, getters }) => {
    const { orgId } = getters;
    if (!orgId) {
      return;
    }
    const end = Vue.moment.utc().startOf('hour');
    const start = end.clone().subtract(1, 'days');

    commit(SET_DATE_RANGE, { start: start.format(), end: end.format() });

    commit(LIVE_LOADING, true);
    commit(SAVE_LIVE_STATS, {});
    const statsData = await fetcher.getOrgStats({ orgId, start, end })
      .catch(actions.handleException);
    if (!statsData.error) {
      commit(SAVE_LIVE_STATS, statsData);
    }
    commit(LIVE_LOADING, false);
  },
  loadDates: async ({ commit }) => {
    commit(DATES_LOADING, true);
    try {
      const weekFut = fetcher.getWeekDate();
      const monthFut = fetcher.getMonthDate();
      const yearFut = fetcher.getYearDate();
      const { date: week } = await weekFut;
      const { date: month } = await monthFut;
      const { date: year } = await yearFut;
      commit(SAVE_DATES, { week, month, year });
    } catch (ex) {
      // eslint-disable-next-line no-console
      console.log(ex);
      commit(SAVE_DATES, { week: '', month: '', year: '' });
    }
    commit(DATES_LOADING, false);
  },
};

const rankings = {
  state,
  mutations,
  actions,
  getters,
};

export {
  rankings,
  fetcher,
};
