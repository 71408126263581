<template>
  <div>
    <slot><span v-if="!noText">Loading...</span></slot>
    <BSpinner />
  </div>
</template>

<script>
import { BSpinner } from 'bootstrap-vue';

export default {
  name: 'LoadingComponent',
  components: {
    BSpinner,
  },
  props: {
    noText: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style type="text/css">
@keyframes ldio-4ndwdj85z26 {
  0% { opacity: 1 }
  100% { opacity: 0 }
}
.ldio-4ndwdj85z26 div {
  left: 47.5px;
  top: 2px;
  position: absolute;
  animation: ldio-4ndwdj85z26 linear 1.5384615384615383s infinite;
  background: var(--accent);
  width: 5px;
  height: 24px;
  border-radius: 2.5px / 4.5600000000000005px;
  transform-origin: 2.5px 48px;
  position: absolute;
}.ldio-4ndwdj85z26 div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.4102564102564101s;
  background: var(--accent);
}.ldio-4ndwdj85z26 div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1.282051282051282s;
  background: var(--accent);
}.ldio-4ndwdj85z26 div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -1.1538461538461537s;
  background: var(--accent);
}.ldio-4ndwdj85z26 div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -1.0256410256410255s;
  background: var(--accent);
}.ldio-4ndwdj85z26 div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.8974358974358974s;
  background: var(--accent);
}.ldio-4ndwdj85z26 div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.7692307692307692s;
  background: var(--accent);
}.ldio-4ndwdj85z26 div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.641025641025641s;
  background: var(--accent);
}.ldio-4ndwdj85z26 div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.5128205128205128s;
  background: var(--accent);
}.ldio-4ndwdj85z26 div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3846153846153846s;
  background: var(--accent);
}.ldio-4ndwdj85z26 div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2564102564102564s;
  background: var(--accent);
}.ldio-4ndwdj85z26 div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1282051282051282s;
  background: var(--accent);
}.ldio-4ndwdj85z26 div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
  background: var(--accent);
}
.loadingio-spinner-spinner-845qmermda6 {
  width: 24px;
  height: 24px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-4ndwdj85z26 {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.24);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-4ndwdj85z26 div { box-sizing: content-box; }
/* generated by https://loading.io/ */
</style>
