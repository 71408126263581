<template>
  <BNavbar
    id="footer"
    bg="dark"
    variant="dark"
    type="dark"
    class="justify-content-between"
  >
    <BNavbarBrand href="/">
      <img
        alt="APImetrics logo"
        src="../assets/api-expert-logo-no-border.svg"
        class="d-inline-block align-top footer-logopic"
      >
      <p class="copyright">
        &copy; APImetrics Inc, 2024.
      </p>
    </BNavbarBrand>
    <BNavbarNav id="not-logo">
      <BNavbarNav id="terms">
        <BNavText>
          <p class="accent">
            Terms &amp; Conditions
          </p>
          <a href="https://apimetrics.io/privacy/">
            <p>Privacy</p>
          </a>
          <a href="https://apimetrics.io/tos/">
            <p>Terms of Service</p>
          </a>
        </BNavText>
      </BNavbarNav>
      <BNavbarNav>
        <BNavText>
          <p class="accent">
            About
          </p>
          <p>
            <a href="https://apimetrics.io">
              <InfoIcon
                size="25"
                color="#4B5C71"
                class="footer-icons"
              />
              <span class="inline">
                APImetrics
              </span>
            </a>
          </p>
          <p>
            <router-link :to="{ name: 'api-cloud-analysis' }">
              <UploadCloudIcon
                size="25"
                color="#4B5C71"
                class="footer-icons"
              />
              <span class="inline">
                APIs 2020 Report
              </span>
            </router-link>
          </p>
          <p>
            <router-link to="/about/">
              <InfoIcon
                size="25"
                color="#4B5C71"
                class="footer-icons"
              />
              <span class="inline">
                API.expert
              </span>
            </router-link>
          </p>
          <p>
            <router-link to="/about/casc/">
              <UploadCloudIcon
                size="25"
                color="#4B5C71"
                class="footer-icons"
              />
              <span class="inline">
                CASC
              </span>
            </router-link>
          </p>
        </BNavText>
      </BNavbarNav>
      <BNavbarNav>
        <BNavText>
          <p class="accent">
            Contact Us
          </p>
          <a
            href="#"
            data-name="support"
            data-domain="apimetrics"
            data-tld="io"
            aria-label="Email"
            @click.prevent="emailLink"
            @keyup.enter="emailLink"
          >
            <MailIcon
              size="25"
              color="#4B5C71"
              class="footer-icons"
            />
            <p
              class="inline email hidemail"
              data-name="support"
              data-domain="apimetrics"
              data-tld="io"
            >&nbsp;</p>
          </a>
          <br>
          <a href="tel:+18332746389">
            <TabletIcon
              size="25"
              color="#4B5C71"
              class="footer-icons"
            />
            <p class="inline">
              +1 (833) 274
              <span>-</span>
              6389
            </p>
          </a>
        </BNavText>
      </BNavbarNav>
      <BNavbarNav id="footCenter">
        <BNavText>
          <p class="accent">
            Get Social
          </p>
          <a
            href="https://www.facebook.com/apimetrics/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Facebook"
          >
            <FacebookIcon
              size="35"
              color="white"
              class="footer-icons"
            />
          </a>
          <a
            href="https://twitter.com/api_expert"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Twitter"
          >
            <TwitterIcon
              size="35"
              color="white"
              class="footer-icons"
            />
          </a>
          <a
            href="https://www.youtube.com/channel/UCsq5SraE_zCr5BDCHq9k4iQ"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="YouTube"
          >
            <YoutubeIcon
              size="35"
              color="white"
              class="footer-icons"
            />
          </a>
          <a
            href="https://www.linkedin.com/company/apimetrics/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="LinkedIn"
          >
            <LinkedinIcon
              size="35"
              color="white"
              class="footer-icons"
            />
          </a>
        </BNavText>
      </BNavbarNav>
    </BNavbarNav>
  </BNavbar>
</template>

<script>
import {
  BNavbar, BNavbarBrand, BNavText, BNavbarNav,
} from 'bootstrap-vue';
import {
  MailIcon, TabletIcon, FacebookIcon, TwitterIcon,
  YoutubeIcon, LinkedinIcon, InfoIcon, UploadCloudIcon,
} from 'vue-feather-icons';

export default {
  name: 'FooterComponent',
  components: {
    BNavbar,
    BNavbarBrand,
    BNavbarNav,
    BNavText,
    MailIcon,
    TabletIcon,
    FacebookIcon,
    TwitterIcon,
    YoutubeIcon,
    LinkedinIcon,
    InfoIcon,
    UploadCloudIcon,
  },
  methods: {
    emailLink(ev) {
      const targ = ev.currentTarget;
      window.open(`mailto:${targ.dataset.name}@${targ.dataset.domain}.${targ.dataset.tld}`);
    },
  },
};
</script>

<style scoped>
.hidemail:after {
  content: attr(data-name) "@" attr(data-domain) "." attr(data-tld);
}
</style>
