import Vue from 'vue';
import VueRouter from 'vue-router';
import NotFoundPage from '../views/NotFoundPage.vue';
import LoadingComponent from '../components/Loading.vue';
import ErrorComponent from '../components/Error.vue';
import routes from './routes';

const delay = 2;
const timeout = 10000;

Vue.component('LoadingAnim', LoadingComponent);
Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    ...routes.map((route) => {
      if (route.component) {
        return {
          ...route,
          loading: LoadingComponent,
          error: ErrorComponent,
          delay,
          timeout,
        };
      }
      return route;
    }),
    { path: '*', component: NotFoundPage },
  ],
});

router.onError = ((error) => {
  if (/loading chunk \d* failed./i.test(error.message)) {
    window.location.reload();
  }
});

export default router;
