import allCollections from '../data/collections.json';

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/collection/search',
  },
  {
    path: '/industry/cloud*',
    name: 'industry-cloud',
    redirect: '/collection/cloud-service-providers',
    meta: { sitemap: { ignoreRoute: true } },
  },
  {
    path: '/industry/messaging*',
    name: 'industry-messaging',
    redirect: '/collection/messaging-cpaas-solutions',
    meta: { sitemap: { ignoreRoute: true } },
  },
  {
    path: '/industry/exchanges*',
    name: 'industry-exchanges',
    redirect: '/collection/crypto-currency-exchanges',
    meta: { sitemap: { ignoreRoute: true } },
  },
  {
    path: '/industry/corpinfra*',
    name: 'industry-corpinfra',
    redirect: '/collection/enterprise-it',
    meta: { sitemap: { ignoreRoute: true } },
  },
  {
    path: '/industry/enterprisemessaging*',
    name: 'industry-enterprisemessaging',
    redirect: '/collection/enterprise-messaging',
    meta: { sitemap: { ignoreRoute: true } },
  },
  {
    path: '/industry/psd2*',
    name: 'industry-psd2',
    redirect: '/collection/open-banking-psd2-sandboxes',
    meta: { sitemap: { ignoreRoute: true } },
  },
  {
    path: '/industry/social*',
    name: 'industry-social',
    redirect: '/collection/social-networks',
    meta: { sitemap: { ignoreRoute: true } },
  },
  {
    path: '/industry/ukgov*',
    name: 'industry-ukgov',
    redirect: '/collection/government-uk',
    meta: { sitemap: { ignoreRoute: true } },
  },
  {
    path: '/industry/openbankingukproduction*',
    name: 'industry-openbankingukproduction',
    redirect: '/collection/open-banking-uk-production',
    meta: { sitemap: { ignoreRoute: true } },
  },
  {
    path: '/industry/openbankinguk*',
    name: 'industry-openbankinguk',
    redirect: '/collection/open-banking-uk-sandboxes',
    meta: { sitemap: { ignoreRoute: true } },
  },
  {
    path: '/industry/usgovernment*',
    name: 'industry-usgovernment',
    redirect: '/collection/government-us',
    meta: { sitemap: { ignoreRoute: true } },
  },
  {
    path: '/industry/ukbanks*',
    name: 'industry-ukbanks',
    redirect: '/collection/open-banking-uk-open-data',
    meta: { sitemap: { ignoreRoute: true } },
  },
  {
    path: '/industry/:colId/*',
    name: 'industry-domain',
    redirect: '/collection/:colId',
    meta: { sitemap: { ignoreRoute: true } },
  },
  {
    path: '/industry/:colId',
    name: 'industry',
    redirect: '/collection/:colId',
    meta: { sitemap: { ignoreRoute: true } },
  },
  {
    path: '/collection/us-government',
    name: 'collection-beta-us-government',
    redirect: '/collection/government-us',
    meta: { sitemap: { ignoreRoute: true } },
  },
  {
    path: '/collection/uk-government',
    name: 'collection-beta-uk-government',
    redirect: '/collection/government-uk',
    meta: { sitemap: { ignoreRoute: true } },
  },
  {
    path: '/collection/cpaas-solutions',
    name: 'collection-beta-cpaas-solutions',
    redirect: '/collection/messaging-cpaas-solutions',
    meta: { sitemap: { ignoreRoute: true } },
  },
  {
    path: '/collection/uk-open-banking:splat',
    name: 'collection-beta-uk-open-banking',
    redirect: '/collection/open-banking-uk:splat',
    meta: { sitemap: { ignoreRoute: true } },
  },
  {
    path: '/collection/open-banking-uk',
    name: 'collection-group-parent',
    redirect: '/collection/open-banking-uk-production',
    meta: { sitemap: { ignoreRoute: true } },
  },
  {
    path: '/api-cloud-analysis',
    name: 'api-cloud-analysis',
    beforeEnter() {
      window.location.href = 'https://api-cloud-analysis.api.expert/';
    },
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutPage.vue'),
  },
  {
    path: '/about/casc',
    name: 'casc',
    component: () => import(/* webpackChunkName: "about" */ '../views/CascPage.vue'),
  },
  {
    path: '/news',
    name: 'news',
    component: () => import(/* webpackChunkName: "news" */ '../views/NewsPage.vue'),
  },
  ...Object.keys(allCollections).map((colId) => ({
    path: `/collection/${colId}`,
    name: `collection-${colId}`,
    component: () => import(/* webpackChunkName: "main" */ '../views/Main.vue'),
    meta: {
      colId,
      sitemap: {
        changefreq: 'weekly',
        priority: 1.0,
        ignoreRoute: allCollections[colId].hidden,
      },
    },
  })),
  ...Object.keys(allCollections).map((colId) => ({
    path: `/collection/${colId}/:domain/`,
    name: `collection-domain-${colId}`,
    component: () => import(/* webpackChunkName: "main" */ '../views/Main.vue'),
    // Ignore this in the sitemap
    meta: { colId, sitemap: { ignoreRoute: true } },
  })),
  {
    path: '/standalone/collection/',
    name: 'standalone-collection',
    component: () => import(/* webpackChunkName: "collection-table" */ '../standalone/CollectionTable.vue'),
    // Ignore this in the sitemap
    meta: { sitemap: { ignoreRoute: true } },
  },
  {
    path: '/standalone/provider/',
    name: 'standalone-provider',
    component: () => import(/* webpackChunkName: "provider-table" */ '../standalone/ProviderTable.vue'),
    // Ignore this in the sitemap
    meta: { sitemap: { ignoreRoute: true } },
  },
  {
    path: '/standalone/full/',
    name: 'standalone-full',
    component: () => import(/* webpackChunkName: "full-page" */ '../standalone/FullPage.vue'),
    // Ignore this in the sitemap
    meta: { sitemap: { ignoreRoute: true } },
  },
];

export default routes;
