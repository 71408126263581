import sortBy from 'lodash/sortBy';
import reverse from 'lodash/reverse';
import extraDomainInfo from '../../../data/domains_full.json';
import allCollections from '../../../data/collections.json';

function prepData(data) {
  return reverse(sortBy(data, 'avg_casc_score')).map((api, index) => {
    const {
      dev_url: devUrl = '', postman_url: postmanUrl = '', banks_auth: bankingUserAuth = '',
    } = (extraDomainInfo[api.domain] || {});
    const rank = index + 1;
    return {
      ...api, rank, devUrl, postmanUrl, bankingUserAuth,
    };
  });
}

function prepareDetailsData(data) {
  return data.map((api) => {
    if (api.cloud === 'IBM') {
      return { ...api, cloud: 'IBM Cloud' };
    }
    return { ...api };
  });
}

export {
  prepData, allCollections, prepareDetailsData,
};
