<template>
  <div
    id="app"
    class="d-flex flex-column min-vh-100"
  >
    <ApxHeader />
    <b-container v-if="updateExists">
      <b-row>
        <b-col>
          <b-alert show>
            A site update is available:
            <b-button
              variant="primary"
              @click="refreshApp"
            >
              Update
            </b-button>
          </b-alert>
        </b-col>
      </b-row>
    </b-container>
    <router-view />
    <ApxFooter class="mt-auto" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ApxHeader from './components/Header.vue';
import ApxFooter from './components/Footer.vue';
import update from './update_mixin';

export default {
  components: {
    ApxHeader,
    ApxFooter,
  },
  mixins: [update],
  metaInfo: {
    title: 'API.expert',
    titleTemplate: '%s - API.expert',
    htmlAttrs: {
      lang: 'en',
    },
    meta: [
      { charset: 'utf-8' },
      { 'http-equiv': 'X-UA-Compatible', content: 'IE=edge' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },

      {
        vmid: 'description',
        name: 'description',
        content: 'API.expert provides actionable API performance and quality API metrics for leading APIs including API uptime, API quality and regional cross-cloud API latency analysis. Whether you’re an API owner or an API developer, API.expert will help you save time, money and customers by delivering access to clear API rankings for all the services you depend on. But you don’t have to be an API expert to use API.expert - simply sign up with one click, and start monitoring leading APIs in your industry today.',
      },

      { vmid: 'og:url', property: 'og:url', content: 'https://www.api.expert/' },
      { property: 'og:type', content: 'website' },
      { vmid: 'og:title', property: 'og:title', content: 'API.expert' },
      {
        vmid: 'og:description',
        property: 'og:description',
        content: 'API.expert provides actionable API performance and quality API metrics for leading APIs including API uptime, API quality and regional cross-cloud API latency analysis. Whether you’re an API owner or an API developer, API.expert will help you save time, money and customers by delivering access to clear API rankings for all the services you depend on. But you don’t have to be an API expert to use API.expert - simply sign up with one click, and start monitoring leading APIs in your industry today.',
      },
      { property: 'og:image', content: 'https://www.api.expert/splash.png' },

      { name: 'twitter:card', content: 'summary_large_image' },
      { property: 'twitter:domain', content: 'api.expert' },
      { property: 'twitter:url', content: 'https://www.api.expert/' },
      { vmid: 'twitter:title', name: 'twitter:title', content: 'API.expert' },
      {
        vmid: 'twitter:description',
        name: 'twitter:description',
        content: 'API.expert provides actionable API performance and quality API metrics for leading APIs including API uptime, API quality and regional cross-cloud API latency analysis. Whether you’re an API owner or an API developer, API.expert will help you save time, money and customers by delivering access to clear API rankings for all the services you depend on. But you don’t have to be an API expert to use API.expert - simply sign up with one click, and start monitoring leading APIs in your industry today.',
      },
      { name: 'twitter:image', content: 'https://www.api.expert/splash.png' },
      { name: 'twitter:site', content: '@api_expert' },
      { name: 'twitter:creator', content: '@apimetrics' },

      { name: 'apple-mobile-web-app-title', content: 'API Cloud Performance Analysis Report' },
      { name: 'application-name', content: 'API Cloud Performance Analysis Report' },
      { name: 'msapplication-TileColor', content: '#603cba' },
      { name: 'theme-color', content: '#121421' },
    ],
    link: [
      { rel: 'apple-touch-icon', sizes: '180x180', href: '/apple-touch-icon.png' },
      {
        rel: 'icon', type: 'image/png', sizes: '32x32', href: '/favicon-32x32.png',
      },
      {
        rel: 'icon', type: 'image/png', sizes: '16x16', href: '/favicon-16x16.png',
      },
      { rel: 'manifest', href: '/site.webmanifest' },
      { rel: 'mask-icon', href: '/safari-pinned-tab.svg', color: '#121421' },
    ],
  },
  computed: {
    ...mapGetters([
      'collections',
    ]),
  },
  mounted() {
    this.$gtag.linker({
      domains: [
        'apimetrics.io', 'auth.apimetrics.io', 'www.api.expert',
        'api-cloud-analysis.api.expert', 'client.apimetrics.io',
      ],
    });
  },
};
</script>
